import React from "react";
import BrandSection from "../components/Brands/Brands";
import Footer from "../components/ulity/Footer";
import Mheader from "./../components/HomePage/Header/Mheader";

const BrandsPage = () => {
  return (
    <div>
      {/* <Mheader /> */}
      <BrandSection />
      <Footer />
    </div>
  );
};

export default BrandsPage;
