import sliderImg01 from "../images/chicken4.png";
import sliderImg02 from "../images/burger1.png";
import sliderImg03 from "../images/slider2.png";
import brandLogo01 from "../logoImage/2.png";
import brandLogo02 from "../logoImage/5.png";
import brandLogo03 from "../logoImage/6.png";

export const sliderData = [
  {
    id: "1",
    title: "Bir Restoran Start-up’ı",
    brand: brandLogo01,
    desc: "Soon Kitchen ortaya koyduğu yenilikçi yaklaşım ve çözümler ile gıda sektörüne farklı bakış açıları sunmak için ortaya çıkmış bir restoran start-up’ıdır.",
    imgUrl: sliderImg01,
  },
  {
    id: "2",
    title: "Tek mutfakta 12 Restoran",
    brand: brandLogo02,
    desc: "Soon Kitchen cloud kitchen konsepti ile tek mutfakta 12 farklı markaya hizmet vermektedir. Gelenekten esinlenerek yenilikçi tatlar oluşturmak istenmektedir.",
    imgUrl: sliderImg02,
  },
  {
    id: "3",
    title: "Gastronominin başkentinden..",
    brand: brandLogo03,
    desc: "Soon Kitchen sürdürülebilir bir iş modeli oluşturarak Gastronominin başkentinden Gastronomi dünyasına açılmayı hedefleyen teknolojik bir gıda işletmesidir.",
    imgUrl: sliderImg03,
  },
  {
    id: "4",
    title: "Dijital Restoran",
    brand: brandLogo03,
    desc: "Feed-backler, datalar, yazılım mühendisleri, analizler. Soon Kitchen gıda sektörüne dijital bakışı ileriye taşımaya çalışmaktadır.",
    imgUrl: sliderImg03,
  },
];
